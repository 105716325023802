import { Box, Chip, Divider, Stack, Typography } from '@mui/material';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { useBreakPoint } from '../../../../helpers/hooks/useBreakPoint';

type DisplayCodeProps = {
  onRowClick?: () => void;
  borderBottom?: boolean;
  code: string;
  description: string;
  version: number;
  isArchived: boolean;
};

export const DisplayCode = ({
  borderBottom,
  onRowClick,
  code,
  description,
  version,
  isArchived,
}: DisplayCodeProps) => {
  const { isDesktop } = useBreakPoint();
  const themeContext = useContext(ThemeContext);

  return (
    <Stack
      direction="row"
      role="button"
      alignItems="center"
      justifyContent="space-between"
      sx={{
        '&:hover': {
          backgroundColor: themeContext.colors.background,
          cursor: 'pointer',
        },
      }}
      spacing={[3, 5]}
      padding={[1, 3]}
      borderBottom={
        borderBottom ? `1px solid ${themeContext.colors.black[12]}` : 'none'
      }
      onClick={onRowClick}
    >
      <Box display="flex" alignItems="center" gap={5}>
        <Typography sx={{width:120}} variant={!isDesktop ? 'caption' : 'body2'}>
          {isDesktop && (
            <Typography
              variant="caption"
              color={themeContext.colors.black[54]}
              fontSize={12}
            >
              Code
              <br />
            </Typography>
          )}
          {code}
        </Typography>
        <Box sx={{ height: '50px' }}>
          <Divider orientation="vertical" light />
        </Box>
        <Typography variant={!isDesktop ? 'caption' : 'body2'}>
          {`${description} (Version ${version})`}
        </Typography>
      </Box>
      {isArchived && <Chip label="Archived" color="primary" />}
    </Stack>
  );
};

export default DisplayCode;
