import { Grid, Paper, Typography } from '@mui/material';

import { groupBy } from 'lodash';
import { SyntheticEvent, useMemo, useState, useContext } from 'react';
import { ThemeContext } from 'styled-components';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../../../sharedComponents/AccordionRow/styled';
import { ProcedureCode, SearchByCode } from '../../state/types';
import { DisplayCode } from '../DisplayCode';

type DisplayProcedureCodesByProps = {
  type: string;
  codes: SearchByCode[];
  onRowClick: (code: ProcedureCode) => void;
  toggleModal?: () => void;
};

const DisplayProcedureCodesBy = ({
  codes,
  type,
  onRowClick,
  toggleModal,
}: DisplayProcedureCodesByProps) => {
  const themeContext = useContext(ThemeContext); 

  const groupedCodes = useMemo(
    () => groupBy(codes, type) as { [key: string]: SearchByCode[] },
    [codes, type]
  );

  const [expanded, setExpanded] = useState('');

  const handleChange =
    (panel?: string) =>
    (event: SyntheticEvent<Element, Event>, newExpanded: boolean) => {
      if (panel) setExpanded(newExpanded ? panel : '');
    };

  return (
    <Grid container width={'100%'} spacing={[2, 3]}>
      {Object.keys(groupedCodes).map((key) => (
        <Grid item key={key} xs={12} md={12}>
          {key !== 'undefined' ? (
            <Accordion
              expanded={expanded === key}
              onChange={handleChange(key)}
              style={{ border: `1px solid ${themeContext.colors.black[12]}` }}
            >
              <AccordionSummary
                style={{
                  borderBottom: `1px solid ${themeContext.colors.black[12]}`,
                  backgroundColor: themeContext.colors.background,
                }}
              >
                <Typography variant="body2">
                  {type === 'task' ? key : `Appointment ${key}`}
                </Typography>
              </AccordionSummary>
              <AccordionDetails>
                {groupedCodes[key]?.map(
                  (code: ProcedureCode, index: number) => (
                    <DisplayCode
                      key={index}
                      onRowClick={() => {
                        onRowClick(code);
                        toggleModal && toggleModal();
                      }}
                      isArchived={code.is_archived}
                      borderBottom={index !== groupedCodes[key].length - 1}
                      code={code.code}
                      description={code.description}
                      version={code.associatedRubric.version}
                    />
                  )
                )}
              </AccordionDetails>
            </Accordion>
          ) : (
            <Grid container spacing={[3, 4]}>
              {groupedCodes[key]?.map((code: ProcedureCode, index: number) => (
                <Grid item xs={12} md={12} key={index}>
                  <Paper elevation={3}>
                    <DisplayCode
                      key={index}
                      onRowClick={() => {
                        onRowClick(code);
                        toggleModal && toggleModal();
                      }}
                      borderBottom={index !== groupedCodes[key].length - 1}
                      code={code.code}
                      description={code.description}
                      version={code.associatedRubric.version}
                      isArchived={code.is_archived}
                    />
                  </Paper>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      ))}
    </Grid>
  );
};

export default DisplayProcedureCodesBy;
